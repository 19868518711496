const { GATSBY_PRISMIC_REPOSITORY } = process.env;

/**
 * A function posting the new referral on the Shine API endpoint.
 */
export const setReferral = async (code: string, phone: string) => {
  const referral = await fetch(`${process.env.GATSBY_API_HOST}/v2/referrals`, {
    method: "POST",
    headers: {
      repository: GATSBY_PRISMIC_REPOSITORY!,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      referralCode: code,
      referreePhone: phone,
    }),
  });
  return referral;
};
