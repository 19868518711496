import { Fragment, FC, useState, FormEvent } from "react";
import {
  Flex,
  Box,
  IconButton,
  Heading,
  Text,
  VStack,
  Image,
  Link,
  Checkbox,
  CircularProgress,
  Center,
} from "@chakra-ui/react";
import { ShiIcon } from "src/atoms";
import {
  STANDARDIZED_INTERNATIONAL_PHONE_FORMAT,
  getFormattedPhoneNumber,
  getRegionCodeForCountryCode,
} from "./phoneNumber";
import PhoneNumberParser from "awesome-phonenumber";
import { useLocation } from "@reach/router";

import useReferral from "./useReferral";
import { ReferralComponentProps } from "./types";
import ShiButton from "src/atoms/ShiButton";
import { Link as GatsbyLink } from "gatsby";
import { setReferral } from "./setReferral";
import PhoneInput from "./PhoneInput";
import handleWebAppRedirection from "src/atoms/PrismicLink/lib/handleWebAppRedirection";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";
import { ClickInfo } from "src/lib/event/types";
import { buildNewAccountClickedEvent } from "src/lib/event";
import { identify, logEvent } from "src/lib/analytics";

const ReferralComponent: FC<ReferralComponentProps> = ({
  code,
  onReferral,
}) => {
  if (!code) {
    throw new Error(`Shouldn't be rendered if code is undefined`);
  }

  const { error, referralCode, referrerName, loading } = useReferral(code);
  const visitorContext = useVisitorContext();
  const location = useLocation();
  const label = "Ouvrir mon compte";

  onReferral(!error && referralCode !== undefined);

  const [countryCode, setCountryCode] = useState<string>("33");
  const [phone, setPhone] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasAgreedTermAndConditions, setHasAgreedTermAndConditions] =
    useState(false);

  const [errorMessagePhone, setErrorMessagePhone] = useState("");
  const [errorMessageTerms, setErrorMessageTerms] = useState("");

  // Updates phone and countryCode based on the last input to phoneFormatterRef.
  const updatePhoneStateWithNumber = (
    updatedCountryCode: string,
    updatedPhone: string,
  ) => {
    const { formattedCountryCode, formattedPhone } = getFormattedPhoneNumber({
      countryCode: updatedCountryCode,
      phoneNumber: updatedPhone,
    });

    setCountryCode(formattedCountryCode);
    setPhone(formattedPhone);
  };

  const onUpdateCountryCode = (updatedCountryCode: string) => {
    updatePhoneStateWithNumber(updatedCountryCode, phone);
  };

  const onUpdatePhone = (updatedPhone: string) => {
    updatePhoneStateWithNumber(countryCode, updatedPhone);
  };

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>,
  ) => {
    event.preventDefault();

    const parsedPhoneNumber = new PhoneNumberParser(
      phone,
      getRegionCodeForCountryCode(countryCode),
    );

    try {
      setErrorMessagePhone("");
      setErrorMessageTerms("");
      setIsLoading(true);

      const hasErrorOnPhone = !phone || !parsedPhoneNumber.isValid();

      if (hasErrorOnPhone) {
        const errMessage = phone
          ? "Le format du numéro de téléphone est invalide"
          : "Veuillez saisir votre numéro de téléphone";
        setErrorMessagePhone(errMessage);
      }

      if (!hasAgreedTermAndConditions) {
        setErrorMessageTerms("Accepter les CGU avant de continuer");
      }

      const hasError = hasErrorOnPhone || !hasAgreedTermAndConditions;

      if (hasError) {
        setIsLoading(false);
        return;
      }
      const formattedPhoneNumber = parsedPhoneNumber.getNumber(
        STANDARDIZED_INTERNATIONAL_PHONE_FORMAT,
      );

      // We identify the user as soon as it is available while respecting the CGU decision
      identify({ phone: formattedPhoneNumber });

      if (referralCode) {
        await setReferral(referralCode, formattedPhoneNumber);
      }
      setIsLoading(false);

      const clickInfo: ClickInfo = {
        label,
        isReferralFlow: true,
        isReferralValid: true,
        fromLocation: location,
      };

      const link = handleWebAppRedirection(visitorContext);
      const event = buildNewAccountClickedEvent(link, clickInfo);

      logEvent(event, () => window.location.assign(link));
    } catch (e) {
      setErrorMessagePhone(
        "Connexion impossible pour le moment, veuillez réessayer ultérieurement",
      );
      setIsLoading(false);
    }
  };

  let content = null;

  type ImageSize = {
    width: string;
    height: string;
  };

  const imageSizes: ImageSize[] = [
    { width: "320", height: "233" },
    { width: "640", height: "466" },
    { width: "960", height: "700" },
    { width: "1024", height: "1024" },
    { width: "2048", height: "2048" },
    { width: "3072", height: "3072" },
    { width: "4096", height: "4096" },
  ];

  const assetUrl =
    "https://images.prismic.io/shine/25be81e0-59e4-4a0a-8be6-b8cc75c58efc_card.jpg";

  if (loading) {
    return (
      <Center flexDirection="column" paddingY="space-128" minHeight="500px">
        <CircularProgress isIndeterminate />
      </Center>
    );
  }

  if (error) {
    content = (
      <Fragment>
        <Flex direction="column" gap="24px" alignItems="flex-start">
          <Heading>Cette offre n&apos;est plus disponible</Heading>

          <Text>
            Votre lien de parrainage est expiré. Vous pouvez toujours ouvrir un
            compte ou découvrir nos offres.
          </Text>

          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems="center"
            gap="space-14"
            width={{ base: "100%", md: "auto" }}
          >
            <ShiButton
              onClick={(e) => {
                e.preventDefault();

                const clickInfo: ClickInfo = {
                  label,
                  isReferralFlow: true,
                  isReferralValid: false,
                  fromLocation: location,
                };

                const link = handleWebAppRedirection(visitorContext);
                const event = buildNewAccountClickedEvent(link, clickInfo);

                logEvent(event, () => window.location.assign(link));
              }}
              width={{ base: "100%", md: "auto" }}
            >
              {label}
            </ShiButton>

            <ShiButton
              as={GatsbyLink}
              variant="primary"
              to="/"
              hasArrow={false}
              width={{ base: "100%", md: "auto" }}
              backgroundColor="yellow-6"
              color="grey-0"
              _hover={{ backgroundColor: "rgba(255, 248, 210, 0.6)" }}
            >
              Découvrir nos offres
            </ShiButton>
          </Flex>
        </Flex>
      </Fragment>
    );
  }

  if (referralCode) {
    content = (
      <Fragment>
        <Flex direction="column" gap="24px" alignItems="flex-start">
          <Heading>Rejoignez plus de 120&nbsp;000 client·es</Heading>

          <Heading fontSize="28px">
            Profitez d’un mois offert supplémentaire grâce au lien partagé par{" "}
            {referrerName}&nbsp;!
          </Heading>
        </Flex>

        <Box as="form" onSubmit={handleSubmit} maxWidth="450px">
          <Text marginTop={{ base: "0", md: "24px" }}>
            Entrez votre numéro de téléphone pour activer votre offre.
          </Text>
          <Box marginTop="space-16">
            <PhoneInput
              countryCode={countryCode}
              onUpdateCountryCode={onUpdateCountryCode}
              onUpdatePhone={onUpdatePhone}
              phone={phone}
              error={errorMessagePhone}
            />

            {errorMessagePhone ? (
              <Text
                color="orange-800"
                marginBottom="space-8"
                marginTop="space-4"
                minHeight="19px"
                fontSize="xs"
              >
                {errorMessagePhone}
              </Text>
            ) : null}
          </Box>

          <Box marginTop="space-16">
            <Checkbox
              checked={hasAgreedTermAndConditions}
              onChange={(e) => setHasAgreedTermAndConditions(e.target.checked)}
            >
              <Text as="span" color="grey.0">
                J’accepte les{` `}
                <Link
                  href="https://www.shine.fr/lp/cgu-ep-en-clair/"
                  target="_blank"
                >
                  <Text as="span" textDecoration="underline" fontWeight="500">
                    conditions d’utilisation
                  </Text>
                </Link>
              </Text>
            </Checkbox>

            {errorMessageTerms ? (
              <Text
                color="orange-800"
                marginBottom="space-8"
                marginTop="space-4"
                minHeight="19px"
                fontSize="xs"
              >
                {errorMessageTerms}
              </Text>
            ) : null}
          </Box>

          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems="center"
            marginTop={{ base: "space-16", md: "space-40" }}
            gap="space-14"
          >
            <ShiButton
              type="submit"
              variant="primary"
              isLoading={isLoading}
              width={{ base: "100%", md: "auto" }}
              color="greyLight-5"
            >
              {label}
            </ShiButton>
            <ShiButton
              as={GatsbyLink}
              variant="primary"
              to="/"
              hasArrow={false}
              width={{ base: "100%", md: "auto" }}
              backgroundColor="yellow-6"
              color="grey-0"
              _hover={{ backgroundColor: "rgba(255, 248, 210, 0.6)" }}
            >
              Découvrir nos offres
            </ShiButton>
          </Flex>
          <Text
            fontSize="small"
            marginTop={{ base: "space-16", md: "space-40" }}
          >
            Pour bénéficier de votre mois offert supplémentaire, vous devrez
            effectuer un paiement de 30 € minimum avec votre carte Shine sous 45
            jours après l’ouverture de votre compte.
          </Text>
        </Box>
      </Fragment>
    );
  }

  return (
    <Flex
      flexDirection={{ base: "column", md: "row" }}
      minHeight="100vh"
      minWidth={{ base: "auto", md: "100vw" }}
      maxWidth={{ base: "480px", md: "auto" }}
      backgroundColor={{ base: "white", md: "yellow-0" }}
      padding={{ base: "space-16", md: "0" }}
      gap={{ base: "space-16", md: "0" }}
      margin="0 auto"
    >
      <Box
        flexGrow={{ base: 0, md: 1 }}
        textAlign="center"
        alignSelf="center"
        width={{ base: "100%", md: "50%" }}
      >
        <Flex
          flexDirection="column"
          textAlign="left"
          padding={{ base: "0", md: "space-80" }}
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <IconButton
              as={GatsbyLink}
              aria-label="Shine logo"
              variant="link"
              padding="6px"
              marginBottom="space-24"
              icon={<ShiIcon name="ShineLogo" width="96px" height="auto" />}
              to="/"
            />

            <VStack
              spacing="space-16"
              align="flex-start"
              backgroundColor={{ base: "yellow-0", md: "transparent" }}
              boxSizing="border-box"
              padding={{ base: "space-24", md: "space-0" }}
              borderRadius={{ base: "16px", md: "0" }}
            >
              {content}
            </VStack>
          </Box>
        </Flex>
      </Box>
      <Box
        flexGrow={{ base: 0, md: 1 }}
        width={{ base: "100%", md: "50%" }}
        height={{ base: "250px", md: "auto" }}
        minHeight={{ base: 0, md: "100%" }}
        borderRadius={{ base: "16px", md: "0" }}
        overflow="hidden"
      >
        <Image
          width="100%"
          height="100%"
          fit="cover"
          srcSet={imageSizes
            .map((size: ImageSize) => {
              const { height, width } = size;
              const rectangularSuffix = width === height ? ",0,1536,1536" : "";
              return `${assetUrl}?auto=compress,format${rectangularSuffix}&w=${width}&h=${height} ${width}w`;
            })
            .join(", ")}
          sizes={imageSizes
            .map(({ width }, index) => {
              const isLast = index === imageSizes.length - 1;
              return isLast
                ? `${width}px`
                : `(max-width: ${width}px) ${width}px`;
            })
            .join(", ")}
        />
      </Box>
    </Flex>
  );
};

export default ReferralComponent;
