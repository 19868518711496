import { useRef, useEffect, useState } from "react";

import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";

const useReferral = (code: string) => {
  const { updateVisitorContext } = useVisitorContext();
  const [referral, setReferral] = useState<{
    referralCode: string | null;
    referrerName: string | null;
    error: boolean;
    loading?: boolean;
  }>({
    referralCode: null,
    referrerName: null,
    error: false,
    loading: true,
  });
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  useEffect(() => {
    fetch(
      `${process.env.GATSBY_API_HOST}/v2/referrals/codes/verify?referralCode=${code}`,
    ).then(async (response) => {
      if (!isMounted.current) {
        return;
      }

      if (response.status !== 200) {
        setReferral({
          referralCode: null,
          referrerName: null,
          error: true,
          loading: false,
        });
      } else {
        const {
          data: { referralCode, firstName },
        } = await response.json();

        setReferral({
          referralCode: referralCode as string,
          referrerName: firstName as string,
          error: false,
          loading: false,
        });

        updateVisitorContext({
          referrerCode: referralCode,
          referrerName: firstName,
        });
      }
    });
  }, [code, updateVisitorContext]);

  return referral;
};

export default useReferral;
